<div class="mat-drawer-backdrop" (click)="_onBackdropClicked()" *ngIf="hasBackdrop"
     [class.mat-drawer-shown]="_isShowingBackdrop()"></div>

<ng-content select="mat-sidenav"></ng-content>

<ng-content select="mat-sidenav-content">
</ng-content>
<mat-sidenav-content *ngIf="!_content">
  <ng-content></ng-content>
</mat-sidenav-content>
